<template>
  <v-form>
    <p class="mb-n2">
      If
      <stemble-latex content="$0.10\,\text{M}$" />
      aqueous solutions of the following solutes were prepared, which would have the lowest freezing
      point?
    </p>

    <v-radio-group v-model="inputs.studentAnswer" class="ml-6 mb-n6" :disabled="!allowEditing">
      <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
        <template #label>
          <stemble-latex :content="option.text" />
        </template>
      </v-radio>
    </v-radio-group>
  </v-form>
</template>

<script>
import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'ChemSIU131Task4',
  components: {CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: '$\\ce{C6H12O6 (sugar)}$',
          value: 'C6H12O6',
        },
        {
          text: '$\\ce{NH4Cl}$',
          value: 'NH4Cl',
        },
        {
          text: '$\\ce{HNO3}$',
          value: 'HNO3',
        },
        {
          text: '$\\ce{Na2S}$',
          value: 'Na2S',
        },
      ],
    };
  },
});
</script>
